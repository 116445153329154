.filters {
  width: 100%;
  
  &-lvl-1 {
    * {
      color: $filter-all;
    }
  }
  
  &-lvl-2 {
    * {
      color: $filter-2-mon;
    }
  }
  
  &-lvl-4 {
    * {
      color: $filter-3-mon;
    }
  }
  
  &-lvl-5 {
    * {
      color: $filter-4-mon;
    }
  }
  
  &-lvl-6 {
    * {
      color: $filter-6-mon;
    }
  }
  
  &-lvl-7 {
    * {
      color: $filter-9-mon;
    }
  }
  
  &-lvl-8 {
    * {
      color: $filter-15-mon;
    }
  }
  
  ul {
    @extend .list-inline;
    @extend .list-unstyled;
    
    li {
      margin-right: 15px !important;
      @extend .list-inline-item;
    }
  }
  
  a {
    font-size: .8rem;
    
    &:before {
      content: "\f073";
      font-family: FontAwesome;
    }
  }
}
