.settingList {
  width: 360px;
  height: 32px;
  color: white;
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;

  &__icon {
    display: inline-block;
    background: #0093d9;
    width: 32px;
    height: 32px;
    padding: 5px 10px;
  }

  &__text {
    display: inline-block;
    background: #014f7d;
    height: 32px;
    width: 100%;
    max-width: 300px;
    padding: 5px 10px;
    font-weight: bold;
    text-align: center;
  }

  &:hover{
    text-decoration: none;
    color: white;
  }

  &:hover &__text{
    background: #0093d9;
  }
}
