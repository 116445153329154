.table {
  color: $grey-4;
  font-family: $font-mono;

  &-documents > tbody > tr> td > a:link{
    font-size: 0.8rem;
  }
  thead {
    th {
      background-color: $grey-2;
      border-bottom: 0;
      border-right: 6px solid $white;
      color: $white;
      font-size: .8rem;
      font-weight: 400;
      padding: 6px 10px;
      text-transform: uppercase;
      
      &:last-child {
        border-right: 0;
      }
    }
    a:link, a:visited{
      color: white;
    }
    a:hover, a:focus{
      text-decoration: none;
      color: $blue-2;
    }
    a.active{
      color: $blue-2;
    }
  }
  
  tbody {
    font-size: .9rem;
    
    tr {
      border-color: $grey-7;
      
      &.done {
        opacity: .5;
      }
    }

    td {
      position: relative;
      border-right: 6px solid transparent;
  
      &:last-child {
        border-right: 0;
      }

      &.has-btn-done {
        padding: 10px;

        span {
          display: block;
        }
      }
    }
  }
  
  ul {
    
    a {
      &:hover {
        .fa {
          color: $blue-1;
        }
      }
    }
    
    .fa {
      color: $blue-2;
    }
  }

  td.short{
    width: 140px !important;
  }
  tr.short{
    width: 140px !important;
  }

  &--blue{
    background: #5ca9dc;
  }
}

.table-clients{
  tbody{
    div:not(.tooltip){
      width: 100%;
      height: 100%;

    }
    td{
      padding: 0;
    }
    a div{
      padding: 12px;
      color: $grey-4;
    }
    a:hover, a:focus{
      text-decoration: none;
    }
  }
}

.table-proceedings{
  tbody{
    div{
      width: 100%;
      height: 100%;

    }
    td{
      padding: 0;
    }
    a div{
      padding: 12px;
      color: $grey-4;
    }
    a:hover, a:focus{
      text-decoration: none;
    }
  }
}

.table-decisions {
  td.table-success {
    background-color: #9be192;
  }

  td.table-danger {
    background-color: #e88484;
  }

  td.table-info {
    background-color: #93def4;
  }
}

.images-table{
  overflow-y: hidden !important;
  padding-bottom: 150px;
}

td.sortableTd{
  width: 20px;
  cursor: pointer;
  padding-top: 17px;
}

tr.sortablePlaceholder{
  background: $grey-1 !important;
  color: white;
}

td.full-href{
  padding: 0;
  height: 100%;

  a:link, a:visited{
    color: #5f5f5f;
    text-decoration: none;
    height: 100%;
    width: 100%;
    display: inline-table;

    div{
      padding: 12px !important;
    }
  }
}

td.underlined-full-href{
  a:link, a:visited{
    color: $blue-2 !important;
    text-decoration: none;
    height: 100%;
    width: 100%;
    display: inline-table;

    div{
      padding: 12px !important;
    }
  }

  a:hover, a:focus{
      text-decoration: underline !important;
  }
}

