@media (max-width: 500px) {
  div.mce-tinymce-inline {
    width: 100% !important;
    overflow: auto;
  }
}

.ieditor {
  min-height: 400px;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.3);
  padding: 40px calc((100% - 625px) / 2);
  background-color: white;
  outline: 0px solid transparent !important;

  @media (max-width: 1000px) {
    overflow: auto;
    padding: 20px !important;
  }

  table tr, table th, table td {
    border: 1px dashed #BBB !important;
  }

  &.mce-content-body {
    /*
   all the styles below are default styles for content in editor
   if you update this, you should also update /www/docs/style.css
   */

    font-family: Calibri, Arial, sans-serif;
    font-size: 15px;
    color: #000;

    p {
      margin: 0;
    }

    h1, h2, h3, h4, h5, h6 {
      color: #000;
      font-weight: bold;
      text-align: left;
      margin: 10px 0;
    }

    h1 {
      font-size: 23px;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 17px;
    }

    h5 {
      font-size: 13px;
    }

    h6 {
      font-size: 10px;
    }

    table {
      margin-top: 0;
      border: 0;
    }

    table tr, table th, table td {
      border: 0;
      font-size: 15px;
    }

    table td.hasborder, table th.hasborder {
      border: 1px solid #000 !important;
    }

    ul, ol {
      margin-top: 15px;
      margin-bottom: 15px;
      padding-left: 40px;
    }

    strong, b {
      font-weight: bold;
    }

    s {
      text-decoration: line-through;
    }

    i {
      font-style: italic;
    }

    pre {
      font-family: monospace;
      margin: 15px 0;
    }

    blockquote {
      margin-top: 15px;
      margin-bottom: 15px;
      padding: 0 40px;
    }
  }
}
