.homepage-logo {
  @extend .img-fluid;
  margin-bottom: 50px;
}

#prihlasenie {
  #page-wrapper {
    margin-top: 0;
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.detail-profile {
  h1.page-header {
    top: 70px;
  }

  .table-detail-profile {
    table {
      td {
        padding: 0;
        border: none;

        &:hover {
          background-color: transparent;
        }
      }

      tr {
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.grayblock {
  background: #ECEEEF;
  padding: 10px;
}

#timeline #frm-filterForm {
  @media (max-width: 770px) {
    input[type=submit] {
      float: left;
      margin-top: 10px;
    }
  }
}

.typeahead.dropdown-menu {
  max-height: 300px;
  max-width: 100%;
  overflow-y: auto;
}

.m-l-sm {
  margin-left: 5px;
}

.color-inherit {
  color: inherit !important;
}

tr.vmiddle td {
  vertical-align: middle;
}

.form-control.datepicker {
  padding: .5rem .75rem;
}

.form-control-sm.datepicker {
  padding: .25rem .5rem !important;
}

.act-form {
  margin-top: 15px;
}

.text-center {
  text-align: center;
}

.display-none {
  display: none;
}

#textstats {
  text-align: right;
  font-size: 80%;
  color: #333;
  margin: 5px 0;
}
