.search {
  background-color: $white;
  -webkit-box-shadow: 4px 4px 5px 0 rgba(201, 201, 201, 1);
  -moz-box-shadow: 4px 4px 5px 0 rgba(201, 201, 201, 1);
  box-shadow: 4px 4px 5px 0 rgba(201, 201, 201, 1);
  display: inline-block;
  max-width: 380px;
  width: 100%;
  
  input {
    border: 0;
    display: inline-block;
    font-size: .8rem;
    padding: 5px 10px;
    width: calc(100% - 34px);
    
    &:focus {
      outline: 0;
    }
  }
  
  button {
    background-color: $white;
    border: 0;
    cursor: pointer;
    display: inline-block;
    padding: 0 7px 0 0;
    
    &:before {
      border-left: 1px solid $blue-2;
      content: " ";
      padding-left: 5px;
    }
    
    .fa {
      color: $blue-2;
    }
  }
}
