.modal {
  font-family: $font-mono;
  
  &-content {
    border: 0;
    border-radius: 0;
  }
  
  &-header {
    border-bottom: 0;
    padding: 20px 30px 0 30px;
  }
  
  &-title {
    color: $grey-1;
    font-size: .9rem;
  }
  
  &-body {
    padding: 35px 30px 15px 30px;
    
    h4 {
      font-size: 1.3rem;
      line-height: 1.8rem;
    }
  }
  
  &-footer {
    border-top: 0;
    padding-left: 30px;
    padding-right: 30px;
    
    *[data-dismiss="modal"] {
      background: transparent;
      border: 0;
      color: $grey-4;
      
      &:hover {
        text-decoration: underline;
      }
    }
    
    .btn-success {
      -webkit-appearance: inherit;
      background-color: $blue-2;
      border: 0;
      border-radius: 0;
      color: $white;
      
      &:hover {
        background-color: $blue-1;
      }
    }
  }
}
