.hp-blocks {
  margin: 0 auto;
  max-width: 700px;
  
  .col-xs-12 {
    @media (max-width: 576px) {
      margin-bottom: 50px;
    }
  }
}

.hp-block {
  display: inline-block;
  margin-bottom: 20px;
  width: 100%;
  
  &:hover {
    .hp-block--body {
      background: #5084d2;
      background: -moz-radial-gradient(center, ellipse cover, #5084d2 0%, #00a8dc 100%);
      background: -webkit-radial-gradient(center, ellipse cover, #5084d2 0%, #00a8dc 100%);
      background: radial-gradient(ellipse at center, #5084d2 0%, #00a8dc 100%);
    }
  }
  
  &--body {
    background: #00a8dc;
    background: -moz-radial-gradient(center, ellipse cover, #00a8dc 0%, #5084d2 100%);
    background: -webkit-radial-gradient(center, ellipse cover, #00a8dc 0%, #5084d2 100%);
    background: radial-gradient(ellipse at center, #00a8dc 0%, #5084d2 100%);
    color: $white;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00a8dc', endColorstr='#5084d2', GradientType=1);
    float: left;
    font-size: .9rem;
    min-height: 135px;
    padding: 20px 0 10px 0;
    width: 100%;
    
    span {
      float: left;
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 130%;
      text-align: center;
      width: 100%;
      
      .fa {
        font-size: 3.4rem;
        font-weight: 400;
        
        &-cog {
          font-size: 5.9rem;
        }
      }
    }
  }
  
  &--footer {
    background-color: #00507a;
    color: $white;
    float: left;
    font-size: .8rem;
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }
}

.label{
  display: inline;
  padding: 6px 6px 4px 6px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

.label-success{
  background-color: #5cb85c;
}

.add-buttons{
  padding-bottom: 25px
}
