.nav {
  float: right;
  margin-top: 3px;
  
  @media (max-width: 992px) {
    float: left;
    margin-top: 10px;
  }
  
  a {
    color: $blue-2;
    font-size: .8rem;
  }
  
  &--wrapper {
    @extend .list-unstyled;
    @extend .list-inline;
    @extend .mb-0;
  }
  
  &--item {
    @extend .list-inline-item;
    
    &:first-child {
      a {
        @media (max-width: 992px) {
          padding-left: 0;
        }
      }
    }
    
    &__active {
      
      > a {
        border-bottom: 1px solid;
        font-weight: 600;
      }
    }
    
    > a {
      padding: 0 5px;
      text-transform: uppercase;
      
      &:hover,
      &:focus {
        border-bottom: 1px solid;
        text-decoration: none;
      }
    }
  }
}

.dropdown-menu {
  
  &__wider {
    min-width: 14rem;
  }
  
  li:last-child {
    a {
      border-bottom: 0;
    }
  }
  
  a {
    border-bottom: 1px solid $grey-5;
    display: inline-block;
    padding: 5px 10px;
    width: 100%;
    font-size: .9rem;
    
    &.active {
      font-weight: 600;
      color: $grey-4;
      pointer-events: none;
    }
    
    &:hover,
    &:focus {
      text-decoration: none;
      background-color: $grey-3;
    }
    
    .fa {
      margin-right: 5px;
    }
  }
}
