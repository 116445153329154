#page-wrapper {
  background-color: $white;
  float: left;
  margin-bottom: 100px;
  margin-top: 30px;
  width: 100%;
}

.content {
  &--header {
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    
    h2 {
      text-align: center;
    }
  }
  
  .page-header {
    text-align: center;
  }

  .client-profile-header{
    font-size: 30px;
  }
  
  &--homepage {
    .page-header {
      width: 100%;
    }
  }
}

.actions {
  float: right;
  position: relative;
  top: 10px;
}
