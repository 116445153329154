.select2 {
  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }

  &-results {
    font-size: .8rem !important;
  }

  * {
    font-size: .8rem !important;
  }

  &-selection {
    border-color: #e4e4e6 !important;
  }
}

.form-control {
  border-color: #e4e4e6 !important;

  &:focus {
    border-color: #e4e4e6 !important;
  }
}
