$path-to-image: '../img' !default;
$path-to-fonts: '../fonts' !default;

$font-mono: 'Roboto Slab', sans-serif;

// ......................
// Colours

$white: #fff !default;
$black: #000 !default;

$blue-1: #00a8dc !default; // header
$blue-2: #046ebe !default; // headlines, icons, font

$grey-1: #c9c9c9 !default; // header, table border
$grey-2: #b3b3b3 !default; // table th
$grey-3: #f1f1f1 !default; // table td
$grey-4: #5f5f5f !default; // font
$grey-5: #eaeaea !default; // footer
$grey-6: #525252 !default; // footer font
$grey-7: #dadada !default;

// Filters
$filter-all: $grey-4 !default;
$filter-2-mon: $blue-2 !default;
$filter-3-mon: $blue-1 !default;
$filter-4-mon: #3cbce4 !default;
$filter-6-mon: #97dcf1 !default;
$filter-9-mon: #a5a5a5 !default;
$filter-15-mon: #ccc !default;
