.my-messages {
  .panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
  }

  .panel-default {
    border: 1px solid #ddd;
  }

  .panel-body {
    padding: 15px;
  }

  .panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin-bottom: 0;
  }

  .message-unreaded {
    .panel-heading {
      border: 2px solid #337ab7;
      color: white;
      background-color: #337ab7;
    }

    .panel-body {
      border: 2px solid #337ab7;
    }
  }
}
