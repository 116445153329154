.page-header,
h2 {
  color: $blue-2;
  font-family: $font-mono;
  text-align: center;
}

.panel-heading,
h3 {
  color: $blue-2;
  font-family: $font-mono;
  margin-bottom: 15px;
}

.blue {
  color: $blue-2;
}

.strong {
  font-weight: 600;
}