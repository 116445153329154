.logo {
  background-image: url('#{$path-to-image}/logo.png');
  background-repeat: no-repeat;
  display: block;
  height: 60px;
  max-width: 230px;
  width: 100%;
  background-size: 100%;

  @media (max-width: 1200px) {
    max-width: 230px;
    height: 45px;
  }
}
