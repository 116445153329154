.forms {

  .col-lg-4 {
    padding-left: 5px;
    padding-right: 5px;
  }
  
  &--headline {
    background-color: #e5f0f8;
    color: $white;
    margin-bottom: 20px;
    padding: 2px 15px;
    min-height: 28px;
    
    &__active {
      background-color: #8cbce1;
    }
  }
  
  &--block {
    border-right: 1px solid #7ab2dd;
    
    &:last-child {
      border-right: 0;
    }
  }
}
