.button-group {
  a {
    display: inline;
    
    &:hover {
      text-decoration: none;
    }
    
    &:after {
      content: "|";
      color: #dadada;
      padding: 0 3px 0 5px;
    }
    
    &:last-child {
      &:after {
        content: " ";
      }
    }
  }
}
