.proceeding {
  &-lvl-2 {
    .proceeding-age-badge {
      background-color: $blue-1;
    }
  }
  
  &-age-badge {
    border-radius: 50px;
    display: inline-block;
    height: 15px;
    position: absolute;
    right: 10px;
    top: 15px;
    width: 15px;
  }
}


.proceeding-group {
  &-item {
    float: left;
    width: 100%;
    border-bottom: 1px solid $grey-1;
    margin-bottom: 10px;
    padding-bottom: 10px;
    
    a {
      color: $grey-4;
    }
    
    .fa {
      position: relative;
      left: 10px;
    }
    
    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
}
