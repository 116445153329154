.list {

  li {
    position: relative;
  }

  &__files {

    .btn__delete {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__icons {
    .fa {
      margin-right: 5px;
    }
  }

  &--md {

    li {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      font-size: .8rem;
    }
  }

  &--lg {

    li {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      font-size: 1.0rem;
    }
  }

  &__icons,
  &--lg {
    .fa {
      margin-right: 10px;
    }
  }

  &__icons,
  &--md {
    .fa {
      margin-right: 5px;
    }
  }
}

.invoices-list {
  background: #eceeef;

  ul {
    list-style: none;
    padding: 0;
    width: 100%;

    li {
      &:not(:first-child) {
        border-top: 1px solid #e4e6e7;
      }

      &.disabled {
        color: #d7d9da;
      }
    }
  }

  a {
    color: inherit;
  }

  .invoice {
    padding: 10px 15px;

    &.active {
      background: #00a8dc;
      color: #fff;
    }

    &.danger {
      background: #dca01f;
      color: #fff;
    }

    & > a > div > div:first-child {
      float: left;
    }

    & > a > div > div:last-child {
      float: right;
    }

    & > a > div:after {
      display: block;
      content: '';
      clear: both;
    }

    .small-text {
      font-size: 80%;
    }

    &:hover, &:focus{
      background-color: #00a8dc;
      color: white;
    }
  }

  .invoice-pagination {
    padding: 10px 0;

    a {
      padding: 0 2px;
    }

    .disabled {
      color: #ccc;
    }

    .active {
      font-weight: bold;
    }
  }
}
