.card {
  border: 0;
  border-radius: 0;
  font-family: $font-mono;
  font-size: .8rem;
  position: relative;
  
  &.messages {
    .card-block-inner {
      margin-bottom: 15px;
    }
  }
  
  &-header {
    background-color: $grey-2;
    border-bottom-width: 0;
    border-radius: 0;
    color: $white;
    font-weight: 400;
    padding: 6px 10px;
    position: relative;
    text-transform: uppercase;
    vertical-align: middle;

    &-title{
      padding-top: 5px
    }

    a {
      color: white;

      &.right {
        float: right;
        text-transform: initial;
      }
    }
  
    &:first-child {
      border-radius: 0;
    }

    .btn {
      margin: -6px -10px;
      display: block;
      padding: 6px 35px;
    }
  }
  
  &-block {
    padding: 1rem 0;
    position: relative;
    
    &-inner {
      padding: 0 1rem;
      position: relative;
    }
  }
  
  .date {
    color: $blue-2;
    font-weight: 700;
  }
  
  .name {
    color: $blue-2;
  }
  
  h2 {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
    text-align: left;
  }
  
  h3 {
    color: $grey-2;
    font-size: .7rem;
    margin-bottom: 7px;
    text-transform: uppercase;
  }
  
  hr {
    margin: 0 0 1rem 0;
    padding: 0;
  }
  
  ul {
    @extend .list-unstyled;
  }
  
  .icon-list {
    position: absolute;
    top: 0;
    right: 0;
    @extend .mb-0;
    @extend .list-unstyled;
    @extend .list-inline;
    
    li {
      @extend .list-inline-item;
    }
    
    .fa {
      color: $grey-2;
      font-size: 125%;
      
      &:hover {
        color: $blue-2;
      }
    }
  }
}
