.detail-profile {
  .rt-wrapper {
    display: table;
    width: 100%;
  }

  .rt-row {
    display: table-row;
  }

  .rt-cell {
    display: table-cell;
    border-right: 6px solid white;
    min-width: 170px;

    &.rt-cell--settings {
      max-width: 160px;
    }

    &.rt-cell--note {
      min-width: 100px;
    }

    &.rt-cell--contact {
      min-width: 100px;
    }
  }

  @media (max-width: 700px) {
    .rt-wrapper, .rt-row, .rt-cell {
      display: block !important;
    }

    .rt-cell--settings {
      max-width: none !important;
    }
  }

  .profile-table {
    overflow-x: inherit;
  }

  @media (max-width: 550px) {
    .profile-table {
      overflow-x: auto;
    }
  }
}
