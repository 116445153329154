.button {
  border-radius: 2px;
  display: inline-block;
  padding: 7px 8px 11px;
  text-align: center;

  &:hover {
    text-decoration: none;
    color: white;
    background: #00507a;
  }

  span {
    display: inline-block;
    padding-left: 5px;
    position: relative;
    top: 2px;
  }

  &__blue {
    background: #00a8dc;
    background: -moz-radial-gradient(center, ellipse cover, #00a8dc 0%, #5084d2 100%);
    background: -webkit-radial-gradient(center, ellipse cover, #00a8dc 0%, #5084d2 100%); /* Old browsers */
    background: radial-gradient(ellipse at center, #00a8dc 0%, #5084d2 100%); /* FF3.6-15 */
    color: $white; /* Chrome10-25,Safari5.1-6 */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00a8dc', endColorstr='#5084d2', GradientType=1); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    font-size: .9rem; /* IE6-9 fallback on horizontal gradient */
  }

  &__white {
    background: #eceeef;
    color: #5f5f5f; /* Chrome10-25,Safari5.1-6 */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00a8dc', endColorstr='#5084d2', GradientType=1); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    font-size: .9rem; /* IE6-9 fallback on horizontal gradient */
    border: solid 1px #5f5f5f;
    &:hover {
      color: #eceeef;
      background: #5f5f5f;
    }
  }

  &__homepage {
    width: 45px;
    font-size: 20px;
    padding: 5px 12px;
    margin-right: 5px;
    margin-bottom: 10px;
  }

  &__plus {
    min-width: 40px;
  }
}

.btn-ico {
  display: inline-block;
  color: $white !important; /* Chrome10-25,Safari5.1-6 */
  padding-left: 10px;
  padding-right: 10px;
  font-size: .9rem; /* IE6-9 fallback on horizontal gradient */

  &-info {
    background: #00a8dc;

    &:hover {
      text-decoration: none;
      color: white;
      background: #00507a;
    }
  }

  &-default {
    background: rgba(106, 102, 107, 0.75);

    &:hover {
      text-decoration: none;
      color: white;
      background: rgba(63, 59, 64, 0.75);
    }
  }

  &-danger {
    background: #d9534f;

    &:hover {
      background: #d43f3a;
    }
  }
}

.btn-mobile-block {
  @media (max-width: 480px) {
    width: 48% !important;
    display: inline-block !important;
    margin-bottom: 2px;
    margin-top: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.btn-short {
  display: inline-block !important;
  padding: 5px 15px;
  text-align: center;
}

.btn {
  border-radius: 0;
}
