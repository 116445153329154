.file-upload {
  display: inline-block;
  border-top: 1px solid $grey-2;
  padding-top: 15px;
  margin-top: 10px;
  
  input[type="file"] {
    max-width: 200px;
    width: 100%;
  }
}
