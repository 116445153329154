.header {
  float: left;
  width: 100%;

  @media screen and (max-width: 991px) {
    background-color: #c9c9c9;
  }

  @media screen and (min-width: 992px) {
    background: #00a8dc; /* Old browsers */
    background: -moz-linear-gradient(left, #00a8dc 33%, #c9c9c9 31%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #00a8dc 33%, #c9c9c9 31%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #00a8dc 33%, #c9c9c9 31%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00a8dc', endColorstr='#c9c9c9', GradientType=1); /* IE6-9 */
  }

  &--block {
    padding: 40px 0 0 0;
    min-height: 115px;

    @media (max-width: 992px) {
      padding: 23px 0 0 0;
    }

    &__grey {
      background: #c9c9c9; /* Old browsers */
      background: -moz-linear-gradient(left, #c9c9c9 0%, #ececec 22%, #c9c9c9 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, #c9c9c9 0%, #ececec 22%, #c9c9c9 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, #c9c9c9 0%, #ececec 22%, #c9c9c9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c9c9c9', endColorstr='#c9c9c9', GradientType=1); /* IE6-9 */
      padding-left: 20px;
    }
  }
}
